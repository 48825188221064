<template>
  <div>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="$emit('close')"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('addTestRunToTestPlans') }}
            </h2>
            <v-btn
              icon
              @click="$emit('close')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="mt-6">
            <v-checkbox
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
            >
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ $t("keepAssignedTo") }}</span>
              </template>
            </v-checkbox>

            <div class="text-left mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testruns.testplan_dialog.content') }}
              </v-label>
            </div>
            <v-text-field
              :placeholder="$t('search')"
              background-color="#F9F9FB"
              class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
              height="38"
              dense
              hide-details
            >
              <template v-slot:prepend-inner>
                <SearchIcon />
              </template>
            </v-text-field>
            <template v-if="plansHasData">
              <v-checkbox
                v-for="(plan, index) in plans"
                :key="index"
                v-model="selectedPlan"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                :hide-details="true"
                :value="plan"
              >
                <template v-slot:label>
                  <span class="fs-14px text-theme-label">{{ plan }}</span>
                </template>
              </v-checkbox>
            </template>
            <template v-else>
              <div class="text-left">
                <span>
                  {{ $t('projects.no_data_available') }}
                </span>
              </div>
            </template>

            <div class="text-left mt-8">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t("createQuickPlan") }} 
              </v-label>
              <v-text-field
                :placeholder="$t('enterName')"
                height="38"
                background-color="#F9F9FB"
                class="field-theme mt-0 pt-1"
              />
            </div>
            <v-btn
              type="submit"
              block
              color="primary"
              :depressed="true"
              class="btn-theme"
              width="100%"
              height="40"
            >
              {{ $t("create") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="168px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="168px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('add') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
export default {
    components: {
        SearchIcon,
    },
    props: {
        value: Boolean,
        plans: Array,
    },
    data() {
        return {
            showDialog: this.value,
            selectedPlan: [],
        };
    },
    computed: {
        plansHasData() {
            return this.plans.length > 0;
        },
    },
    watch: {
        value(newVal) {
            this.showDialog = newVal;
        },
    },
    methods: {
        apply() {
            this.$emit("close");
        },
        cancel() {
            this.$emit("close");
        },
    }
};
</script>

<style>
.v-dialog--fullscreen {
    max-height: 100vh !important;
    width: 400px !important;
    right: 0 !important;
    left: auto !important;
}

.v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>