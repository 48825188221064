<template>
  <div class="file-preview">
    <div
      v-if="internalFiles.length"
      class="file-previews mt-4"
    >
      <div class="preview-item row">
        <div
          v-for="(file, index) in internalFiles"
          :key="index"
          class="preview-container col-4"
        >
          <!-- If the file is an image, show the image preview -->
          <template v-if="isImage(file)">
            <div class="h-full">
              <img
                :src="filePreview(file)"
                class="preview-image"
              >
              <v-btn
                icon
                small
                color="red"
                class="delete-btn"
                @click="removeFile(index)"
              >
                <removeIcon />
              </v-btn>
            </div>
          </template>
          <template v-else>
            <div class="h-full non-image-preview">
              <div class="file-placeholder">
                File: {{ file.name }}
              </div>
              <v-btn
                icon
                small
                color="red"
                class="delete-btn"
                @click="removeFile(index)"
              >
                <removeIcon />
              </v-btn>
            </div>
          </template>
        </div>
      </div>
    </div>

    <v-file-input
      v-model="internalFiles"
      class="file-input-bg field-theme-file round-8 py-10"
      dense
      height="38px"
      placeholder="Browse files"
      hide-details
      multiple 
      @change="$emit('onFileChange', $event)"
    />
  </div>
</template>

<script>
import removeIcon from '@/assets/svg/remove.svg';
export default {
    components: {
        removeIcon
    },
    props: {
        files: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            internalFiles: this.files,
        };
    },
    watch: {
        files(newFiles) {
            this.internalFiles = newFiles; 
        },
    },
    methods: {
        isImage(file) {
            return file.type.startsWith('image/');
        },
        filePreview(file) {
            return URL.createObjectURL(file);
        },
        removeFile(index) {
            this.internalFiles.splice(index, 1);
            this.$emit('update:files', this.internalFiles);
        },
    },
};
</script>

<style scoped>
.preview-container {
    position: relative;
}

.preview-image {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 80px;
    min-height: 80px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 4px;
    border: solid 1px #D0D5DD;
}

/* Grey background for non-image files */
.non-image-preview {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 80px;
    min-height: 80px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px #D0D5DD;
}

.file-placeholder {
    color: #6c757d;
    font-size: 12px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.delete-btn {
    position: absolute !important;
    top: 1.15rem;
    right: 1.15rem;
    background: #fff;
    cursor: pointer !important;
    display: none !important;
    z-index: 1;
}

.preview-container:hover .delete-btn {
    display: block !important;
}
.file-input-bg {
    background: #F9FAFB;
}
</style>
