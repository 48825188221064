<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="data-table-style table-fixed mt-6"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    :value="selectedItems"
    height="100%"
    show-select
    @input="onRowClick"
    @click:row="handleRowClick"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>

    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>
    <template v-slot:[`item.name`]="{item}">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class="getColor(item.priority)">{{ item.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span :class="getColor(item.status)">{{ item.status }}</span>
    </template>
    <template v-slot:[`item.milestone`]="{item}">
      <span
        v-for="(milestone, index) in item.testMilestones"
        :key="index"
        :class="getColor(item.testMilestones[0])"
      >{{ milestone.name }} </span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <span :class="getColor(item.customFields.tags)">{{ item.customFields.tags }}</span>
    </template>
    <template v-slot:[`item.testcases`]="{item}">
      <span class="">{{ item.customFields.caseCount }} test cases</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ item.createdAt }}</span>
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-menu
          open-on-hover
          bottom
          offset-y
          max-width="150px"
          content-class="view-executions-menu"
          z-index="100"
        >
          <template v-slot:activator="{ on }">
            <div
              class="progress-linear"
              v-on="on"
            >
              <div
                class="progress-linear-bar"
                :style="{backgroundColor: item.percentage == 100 ? 'transparent' : '#EAECF0'}"
              >
                <div
                  class="partial passed"
                  :style="{width: `calc(${item.executions.passed/item.customFields.caseCount * 100}%)`}"
                />
                <div
                  class="partial incomplete"
                  :style="{width: `${item.executions.incomplete/item.customFields.caseCount * 100}%`}"
                />
                <div
                  class="partial failed"
                  :style="{width: `${item.executions.failed/item.customFields.caseCount * 100}%`}"
                />
              </div>
              <span class="font-weight-regular ml-3">{{ safePercentage(item?.percentage) }}%</span>
            </div>
          </template>

          <v-list>
            <v-list-item
              v-for="(status, index) in item.executions"
              :key="index"
              dense
              height="35px"
            >
              <v-list-item-title class="text-left">
                <span
                  class="text-capitalize"
                  :style="{color: index == 'passed' ? '#66BB6A' : (index == 'incomplete' ? '#FFA726' : '#EF5350')}"
                >{{ index }} {{ status }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left"
          >
            <v-list-item
              v-if="item.archivedAt === null"
              class="pointer"
              @click="onEdit(item)"
            >
              <EditIcon />
              <v-list-item-content class="ml-2">
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt === null"
              class="pointer"
              @click="onArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('archive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="item.archivedAt"
              class="pointer"
              @click="onUnArchive(item)"
            >
              <UnarchivedIcon />
              <v-list-item-content class="ml-2">
                {{ $t('unarchive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="pointer"
              @click="onDelete(item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div
            v-for="(pic, imgIndex) in item.images"
            :key="imgIndex"
          >
            <v-avatar
              class="ml-n2 custom_border"
              size="30"
            >
              <img :src="pic">
            </v-avatar>
          </div>
          <v-avatar
            v-if="item.showCount"
            class="font-weight-bold gray-ish--text ml-n2"
            color="#ebecf0"
            size="30"
          >
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div class="d-flex flex-row justify-center">
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon color="gray-ish">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                class="pointer"
                @click="onEdit(item)"
              >
                <EditIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('edit') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.status==='active'"
                class="pointer"
                @click="onArchive(item)"
              >
                <ArchieveIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('archive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="item.status==='archived'"
                class="pointer"
                @click="onUnArchive(item)"
              >
                <UnarchivedIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('unarchive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="pointer"
                @click="onDelete(item)"
              >
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">
                  {{ $t('delete') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>

import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import UnarchivedIcon from '@/assets/svg/unarchive24px.svg';

import { formattedDate } from '@/utils/util';

export default {
  components: {
    DeleteIcon,
    EditIcon,
    ArchieveIcon,
    UnarchivedIcon,
  },
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
    clearSelection: Boolean,
  },
  data() {
    return {
      debounce: false,
      selectedItems: [],
    };
  },
  watch: {
    clearSelection(newVal) {
      if (newVal) {
        this.selectedItems = [];
        this.$emit('select-item', this.selectedItems);
      }
    },
  },
  methods: {
    formattedDate,
    onRowClick(item) {
      this.selectedItems = item;
      this.$emit('select-item', this.selectedItems);
    },
    safePercentage(value) {
      const percentage = value;
      return (typeof percentage === 'string' && percentage !== 'NaN') ? percentage : '0';
    },
    handleRowClick(item) {
      this.$emit('select-row', item);
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
    toggleStar(item) {
      this.debounce = true;
      this.$emit('toggle-star', item);
    },
    getColor(priority) {
      switch(priority){
        case "High":
        case "Failed":
          return "font-weight-bold red--text text--lighten-1"
        case "Medium":
        case "Rerun":
          return "font-weight-bold orange--text text--lighten-1"
        case "Low":
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Passed":
          return "font-weight-bold deep-purple--text text--lighten-1"
        case "In progress":
          return "font-weight-bold grey--text text--lighten-1"
      }
    },
  },
};
</script>
<style scoped>
.progress-linear{
  width: 100%;
  display: flex;
  align-items: center;
}
.progress-linear .progress-linear-bar{
    width: 120px !important;
    height: 8px;
    border-radius: 10px;
    display: flex;
}
.progress-linear .progress-linear-bar .partial{
  border-radius: 10px;
  height: 100%;
}
.progress-linear .progress-linear-bar .passed{
  background-color: #66BB6A;
  z-index: 10;
}
.progress-linear .progress-linear-bar .incomplete{
  background-color: #FFA726;
  position: relative;
  right: 5px;
  z-index: 9;
}
.progress-linear .progress-linear-bar .failed{
  background-color: #EF5350;
  position: relative;
  right: 10px;
  z-index: 8;
}

</style>
<style scoped>
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  .v-data-table-header__icon {
    opacity: 1 !important;
  }
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
    background-color: #F9FAFB;
  }
  .pointer {
    cursor: pointer;
  }
  .header_text {
    color: #475467;
    font-weight: 700;
  }
  .custom_color {
    color: #667085;
  }
  .custom_border {
    border: 2px solid #ffffff;
  }
  .v-data-table table {
    border-collapse: collapse;
  }
  .v-data-table th {
    border: none !important;
  }
  .v-data-table td {
    border: none !important;
    cursor: pointer;
  }
  .v-data-table .v-data-table__wrapper tbody tr {
    height: 80px;
  }
  .v-data-table .v-data-table__wrapper tbody tr td {
    height: 80px !important;
    padding-top: 0;
  }
  .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background-color: transparent !important;
  }
</style>