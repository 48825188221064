<template>
  <div>
    <v-dialog
      v-model="isOpenStatus"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">
              {{ $t('edit') }}
            </h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('priority') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedPriority"
              :placeholder="$t('choosePriority')"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              :items="priorities"
              item-text="text"
              item-value="value"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38"
            />
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('tag') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedTags"
              class=" pt-1 mt-0 rounded-lg field-theme custom-prepend"
              placeholder="Tags"
              :items="tags"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              item-value="text"
              item-text="text"
              dense
              height="38px"
              :menu-props="{ offsetY: true }"
              multiple
            />
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex d-flex justify-space-between">
        <v-btn
          width="168px"
          color="#F2F4F7"
          full-width
          height="40"
          depressed
          class="text-capitalize btn-theme"
          elevation="0"
          @click="$emit('closeDialog')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="168px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="$emit('clickSave', selectedPriority, selectedTags)"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import makeTagService from '@/services/api/tag';
import { showErrorToast } from '@/utils/toast';



export default {
  name: 'EditDialog',
  props: {
    isOpen: Boolean,
    priorities: Array,
    items: Array,
  },
  data() {
    return {
      selectedTags: [],
      selectedPriority: "",
      isOpenStatus: this.isOpen,
      tags: [],
    };
  },

  watch: {
    isOpen(newVal) {
      this.isOpenStatus = newVal;
    }
  },
  async created() {
    await this.fetchTags();
  },
  methods: {
    clickOutside() {
      this.$emit("closeDialog");
    },
    async fetchTags() {
      const tagService = makeTagService(this.$api);
      try {
        const response = await tagService.getTags(this.$route.params.handle);
        if (response.status === 200) {
          this.tags = response.data.map((tag) => ({
            text: tag.name,
            value: tag.uid,
          }));
        } else {
          showErrorToast(this.$swal, 'fetchError', { item: 'Tags' });
        }
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'Tags' });
      }
    },
  },
};
</script>

<style>
.select-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
input {
  height: 38px !important;
}

</style>