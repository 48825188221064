<template>
  <div>
    <v-sheet
      color="gray-100"
      class="d-flex align-center justify-center"
      height="50px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-4"> {{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="mb-1 text-start mt-4">
              {{ $t('numberOfTestRuns') }}
            </div>
            <v-range-slider
              v-model="testRunsCountRange"
              style="height: 50px;"
              color="blue"
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testRunsCountRange[0]"
                color="blue"
                class="mr-0 custom_input"
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testRunsCountRange[1]"
                color="blue"
                class="mr-0 custom_input"
              />
            </div>
          </div>
          <div>
            <div class="mb-1 text-start mt-4">
              {{ $t('numberOfTestCases') }}
            </div>
            <v-range-slider
              v-model="testCasesCountRange"
              style="height: 50px;"
              color="blue"
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testCasesCountRange[0]"
                color="blue"
                class="mr-0 custom_input"
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testCasesCountRange[1]"
                color="blue"
                class="mr-0 custom_input"
              />
            </div>
          </div>
          <!-- <div>
            <div class="mb-1 text-start mt-4">{{ $t('numberOfDefects') }}</div>
            <v-range-slider style="height: 50px;" color="blue" v-model="defects"></v-range-slider>

            <div class="d-flex align-center">
              <v-text-field
                value="3"
                color="blue"
                class="mr-0 custom_input"
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                value="25"
                color="blue"
                class="mr-0 custom_input"
              />
            </div>
          </div> -->
          <!-- <v-expansion-panels flat v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('projectAdmin') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  color="blue"
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                  class="custom_input mx-0"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Nate"
                  color="blue"
                  dense
                  label="Nate"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Alex"
                  color="blue"
                  dense
                  label="Alex"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Mandy N."
                  color="blue"
                  dense
                  label="Mandy N."
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Maria A."
                  color="blue"
                  dense
                  label="Maria A."
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Boris N."
                  color="blue"
                  dense
                  label="Boris N."
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->
          <div class="text-start pt-6">
            <div>{{ $t('lastChanges') }}</div>
            <div class="d-flex align-center">
              <v-menu
                v-model="lastChangesStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.lastChanges.start"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date.lastChanges.start"
                  @input="lastChangesStart = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="lastChangesEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.lastChanges.end"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date.lastChanges.end"
                  :min="date.lastChanges.start"
                  @input="lastChangesEnd = false"
                />
              </v-menu>
            </div>
            <div class="mt-4">
              {{ $t('creationDate') }}
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="creationDateStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.creationDate.start"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date.creationDate.start"
                  @input="creationDateStart = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="creationDateEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.creationDate.end"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date.creationDate.end"
                  :min="date.creationDate.start"
                  @input="creationDateEnd = false"
                />
              </v-menu>
            </div>

            <div v-if="$store.state.user.currentAccount.type === 'org'">
              <div class="mb-1 text-start mt-4">
                {{ $t('numberOfUsers') }}
              </div>
              <v-range-slider
                v-model="users"
                style="height: 50px;"
                color="blue"
              />
              <div class="d-flex align-center">
                <v-text-field
                  v-model="users[0]"
                  color="blue"
                  class="mr-0 custom_input"
                />
                <div class="mx-4 font-weight-bold text-h6">
                  -
                </div>
                <v-text-field
                  v-model="users[1]"
                  color="blue"
                  class="mr-0 custom_input"
                />
              </div>
            </div>

            <v-expansion-panels
              v-if="$store.state.user.currentAccount.type === 'org'"
              v-model="panel1"
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  {{ $t('users') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    color="blue"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('search')"
                    class="custom_input mx-0"
                  />
                  <v-checkbox
                    v-for="member in filteredMembers"
                    :key="member.uid"
                    v-model="selectedTags"
                    :value="`${member.firstName} ${member.lastName}`"
                    color="blue"
                    dense
                    :label="`${member.firstName} ${member.lastName}`"
                  />
                  <v-checkbox
                    v-model="selectedTags"
                    value="Project."
                    color="blue"
                    dense
                    :label="$t('show_only_project')"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div class="mt-4">
              {{ $t('noActivity') }}
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="noActivityStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.noActivity.start"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date.noActivity.start"
                  @input="noActivityStart = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="noActivityEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.noActivity.end"
                    prepend-icon="mdi-calendar-blank-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date.noActivity.end"
                  :min="date.noActivity.start"
                  @input="activeOnlyEnd = false"
                />
              </v-menu>
            </div>
            <div class="d-flex justify-end mt-5">
              <v-btn
                width="10rem"
                color="gray-100"
                full-width
                class="mr-4 text-capitalize"
                elevation="0"
                @click="clearAll"
              >
                {{
                  $t('clearAll') }}
              </v-btn>
              <v-btn
                width="10rem"
                color="blue"
                dark
                full-width
                elevation="0"
                class="text-capitalize"
                @click="apply"
              >
                {{
                  $t('apply')
                }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import dayjs from 'dayjs';
export default {
  name: "ProjectFilter",
  props: {
    selectedRoles: Array,
    selectedTags: Array,
    filteredMembers: Array,
  },
  data() {
    return {
      panel: 0,
      testRunsCountRange: [0, 100],
      testCasesCountRange: [0, 100],
      // defects: [0, 100],
      users: [0, 100],
      panel1: 0,
      date: {
        lastChanges: {
          start: '',
          end: '',
        },
        creationDate: {
          start: dayjs().format('YYYY-MM-DD'),
          end: dayjs().format('YYYY-MM-DD'),
        },
        noActivity: {
          start: '',
          end: '',
        },
      },
      lastChangesStart: false,
      lastChangesEnd: false,
      creationDateStart: false,
      creationDateEnd: false,
      noActivityStart: false,
      noActivityEnd: false,
      showDialog: false,
    };
  },
  methods: {
    apply() {
      const queryParams = {
        creationStartDate: this.date.creationDate.start,
        creationEndDate: this.date.creationDate.end,
        lastChangeStartDate: this.date.lastChanges.start,
        lastChangeEndDate: this.date.lastChanges.end,
        noActivityStartDate: this.date.noActivity.start,
        noActivityEndDate: this.date.noActivity.end,
        minTestRuns: this.testRunsCountRange[0],
        maxTestRuns: this.testRunsCountRange[1],
        minTestCases: this.testCasesCountRange[0],
        maxTestCases: this.testCasesCountRange[1],
      };
      this.$emit('applyFilters', queryParams,true);
      this.showDialog = false;
    },
    clearAll() {
      this.testRunsCountRange = [0, 100];
      this.testCasesCountRange = [0, 100];
      this.users = [0, 100];
      this.date = {
        lastChanges: {
          start: '',
          end: '',
        },
        creationDate: {
          start: new Date().toISOString().substr(0, 10),
          end: new Date().toISOString().substr(0, 10),
        },
        noActivity: {
          start: '',
          end: '',
        },
      };
      this.selectedTags = [];
      this.$emit('applyFilters', {},false);
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
