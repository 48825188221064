<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-space-between mt-4">
      <div class="d-flex flex-row justify-start">
        <iconDots />
        <h3 class="ml-2">
          Step {{ index + 1 }}
        </h3>
      </div>
      <button @click="deleteStep">
        <removeIcon />
      </button>
    </div>
    <div class="d-flex flex-column mt-4">
      <v-label class="text-left fs-14px text-theme-label font-weight-medium">
        {{ $t("name") }} <strong class="red--text text--lighten-1">*</strong>
      </v-label>
      <v-text-field
        v-model="step.title"
        type="text"
        background-color="#F9F9FB"
        class="field-theme rounded-lg"
        :placeholder="$t('name')"
        height="38"
        :rules="titleValidation"
        @input="editStepItem"
      />
      <v-label class="text-left fs-14px text-theme-label font-weight-medium">
        {{ $t("sharedStepPage.stepDesctiption") }} <strong class="red--text text--lighten-1">*</strong>
      </v-label>
      <v-textarea
        v-model="step.description"
        type="text"
        auto-grow
        background-color="#F9F9FB"
        class="field-theme rounded-lg"
        placeholder="Description"
        :rules="descriptionValidation"
        @input="editStepItem"
      />
      <v-label class="text-left fs-14px text-theme-label font-weight-medium">
        {{ $t("sharedStepPage.expectedResult") }} <strong class="red--text text--lighten-1">*</strong>
      </v-label>
      <v-textarea
        v-model="step.expectedResult"
        type="text"
        background-color="#F9F9FB"
        auto-grow
        placeholder="Expected result"
        class="field-theme rounded-lg"
        :rules="resultValidation"
        @input="editStepItem"
      />
    </div>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import removeIcon from '@/assets/svg/remove.svg';
import { requiredFieldValidationRules } from "@/utils/validation";

export default {
  name: 'StepItem',
  components: {
    iconDots,
    removeIcon,
  },
  props: ['data', 'index'],
  data()
  {
    return {
      step: {
        title: '',
        description: '',
        expectedResult: '',
      },
      id: '',
      titleValidation: requiredFieldValidationRules(this),
      descriptionValidation: requiredFieldValidationRules(this),
      resultValidation: requiredFieldValidationRules(this),
    };
  },
  created()
  {
    this.step = {
      ...this.data
    }
  },
  methods: {
    deleteStep()
    {
      this.$emit('delete-step', this.index);
    },
    editStepItem()
    {
      this.$emit('input-step', this.index, this.step);
    },
  },
};
</script>
