<template>
  <v-container
    fluid
    style="padding: 0"
  >
    <v-row>
      <v-col :cols="isMenuCollapsed ? 1 : 2">
        <left-menu :menu-items="menuItems" />
      </v-col>

      <v-col
        :cols="isMenuCollapsed ? 11 : 10"
        style="padding-left: 0 !important"
      >
        <router-view />
      </v-col>
    </v-row>
    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { createNamespacedHelpers } from 'vuex';

import LeftMenu from '@/components/Admin/LeftMenu';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'AdminLayout',

  components: {
    LeftMenu,
  },

  computed: {
    ...mapGetters(['isMenuCollapsed']),

    ...mapState(['currentAccount', 'user']),

    versionString() {
      if (
        process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
      ) {
        return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
      }
      return '';
    },
    menuItems() {
      const handle = this.currentAccount.handle
      const username = this.user.handle

      const orgMenuItems = [
        { title: 'Users', icon: 'mdi-account-multiple-outline', to: `/${handle}/admin/users`, isActive: true },
        { title: 'Roles', icon: 'mdi-account-multiple-check-outline', to: `/${handle}/admin/roles`, isActive: false },
      ]

    

      return [
        ...(this.$route.params.handle !== username ? orgMenuItems : []),
        { title: 'Tags', icon: 'mdi-tag-multiple-outline', to: `/${handle}/admin/tags`, isActive: false },
      ]
    }
  },
}
</script>

<style>
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
