<template>
  <div>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="$emit('close')"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('testruns.duplicateApplyConfig') }}
            </h2>
            <v-btn
              icon
              @click="$emit('close')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="mt-6">
            <v-checkbox
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
            >
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ $t("keepAssignedTo") }}</span>
              </template>
            </v-checkbox>

            <div class="toggle-wrapper mt-6">
              <div class="toggle-container">
                <div
                  class="toggle-option"
                  :class="{ active: selectedPlan === 0 }"
                  @click="setPlan(0)"
                >
                  Simple set-up
                </div>
                <div
                  class="toggle-option"
                  :class="{ active: selectedPlan === 1 }"
                  @click="setPlan(1)"
                >
                  Matrix set-up
                </div>
              </div>
            </div>

            <div class="text-left mt-8">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium text-capitalize">
                {{ $t('groups') }}
              </v-label>
            </div>
            
            <template v-if="configurationsHasData">
              <div
                v-for="(item, index) in getConfigurations"
                :key="index"
                class="mb-5"
              >
                <GroupSelection
                  v-model="selectedConfigurations"
                  :title="item.name"
                  :add-label="$t('add')"
                  :search-placeholder="$t('search')"
                  :items="item.options"
                />
              </div>
            </template>

            <div class="d-flex justify-start">
              <v-btn
                width="204.5px"
                color="#F2F4F7"
                full-width
                height="40"
                :depressed="true"
                class="text-capitalize btn-theme"
                elevation="0"
                @click="cancel"
              >
                <v-icon>mdi-plus</v-icon>
                <span>{{ $t('plans.create.testRuns.addConfiguration') }}</span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="168px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="168px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('add') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import GroupSelection from './GroupSelection.vue';

export default {
    components: {
        GroupSelection
    },
    props: {
        value: Boolean,
        configurations: Array,
    },
    data() {
        return {
            showDialog: this.value,
            selectedPlan: 0,
            selectedConfigurations: [],
        };
    },
    computed: {
        getConfigurations() {
            return this.configurations;
        },
        configurationsHasData() {
            return this.configurations.length > 0;
        },
    },
    watch: {
        value(newVal) {
            this.showDialog = newVal;
        },
    },
    methods: {
        apply() {
            this.$emit("close");
        },
        cancel() {
            this.$emit("close");
        },
        setPlan(planType) {
            this.selectedPlan = planType;
        },
    },
};
</script>

<style>
.v-dialog--fullscreen {
    max-height: 100vh !important;
    width: 400px !important;
    right: 0 !important;
    left: auto !important;
}

.v-expansion-panel-content__wrap {
    padding: 0 !important;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.toggle-container {
    display: flex;
    background: #F9FAFB;
    border-radius: 8px;
    padding: 4px;
    position: relative;
    width: 100%;
    max-width: 421px;
}

.toggle-option {

    border-radius: 8px;
    color: #667085;
    transition: background 0.3s;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 0;
    width: 50%;
    cursor: pointer;
}

.toggle-option.active {
    font-weight: 600;
    background: #fff;
    color: #0A26C3;
    transition: background 0.3s;
    border-radius: 8px;
    box-shadow: 0px 16px 40px 0px #0000000F !important;
}
</style>