<template>
  <v-menu
    content-class="custom_ele rounded-lg shadow-theme"
    nudge-bottom="50"
    min-width="191"
    :close-on-content-click="false"
    left
  >
    <template v-slot:activator="{ on }">
      <v-sheet
        color="#F2F4F7"
        width="40px"
        class="d-flex align-center justify-center cursor-pointer v-sheet-theme"
        height="40px"
        rounded="lg"
        :outlined="true"
        v-on="on"
      >
        <GearIcon />
      </v-sheet>
    </template>
    <v-list
      :flat="true"
    >
      <h4 class="font-weight-regular text-start ml-4 mb-4 mt-2">
        {{ $t('columns') }}
      </h4>
      <template v-for="(item, index) in filterItems">      
        <v-list-item
          v-if="item.text"
          :key="index"
          dense
        >
          <v-list-item-action start>
            <v-checkbox 
              v-model="item.checked" 
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
            >
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ item.text }}</span>
              </template>
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-list-item
        class="pl-5 mt-2 fw-semibold px-0 fs-14px"
        :ripple="false"
        :class="{ 'text-theme-primary': hasUnchecked,
                  'text-theme-disabled': !hasUnchecked
        }"
        @click="restore"
      >
        {{ $t('restoreDefault') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import GearIcon from '@/assets/svg/gear.svg';
export default {
  components: {
    GearIcon
  },
  props: {
    filterItems: Array,
    requiredItems: Array
  },
  computed: {
    hasUnchecked() {
      return this.filterItems.some(item => !item.checked);
    }
  },
  methods: {
    restore() {
      this.filterItems.forEach(item => {
        if(!this.requiredItems || !this.requiredItems.length)
          return item.checked = true;

        item.checked = this.requiredItems.includes(item.text);
      });
    },
  }
};
</script>
