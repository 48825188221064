<template>
  <!-- header section -->
  <div
    class="card bg-white rounded-lg ml-1 pa-6"
  >
    <DeleteResultDialog
      :value="showDeleteConfirmDialog"
      @input="showDeleteConfirmDialog = !showDeleteConfirmDialog"
      @respond="handleDeleteResponse"
    />
    <v-dialog
      v-model="testDialog"
      max-width="594"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex justify-between">
          <h4>{{ selectedResultUid ? $t('editResult') : $t('addResult') }}</h4>
          <v-btn 
            text 
            small 
            depressed 
            @click="closeTestDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form class="text-left">
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t('status') }}
            </v-label>
            <v-select 
              v-model="resultState" 
              placeholder="Select status" 
              :items="resultStatus" 
              :hide-details="true" 
              append-icon="mdi-chevron-down"
              class="mt-0 pt-1 rounded-lg field-theme custom-prepend mb-4"
              height="38px"
              background-color="#F9F9FB"  
              :menu-props="{'offset-y': true}"
            />
            <label class="text-weight-black result-form-label">{{ $t('addComment') }}</label>
            <VueEditor 
              v-model="resultComment" 
              :editor-toolbar="customToolbar" 
              class="mb-4" 
            />
            <div class="result-attachments">
              <label class="text-weight-black result-form-label">{{ $t('addAttachment') }}</label>
              <label
                id="input-file-browser"
                for="input-file"
                class="mb-4"
              >
                <div>
                  <p>{{ $t('browseFile') }}</p>
                </div>
              </label>
              <v-file-input
                id="input-file"
                v-model="files"
                class="d-none"
                multiple
                loading
                counter
              />
              <!-- New Attachments -->
              <div class="step-item-attachments d-flex flex-column align-start">
                <div
                  v-for="(item,index) in files"
                  :key="index"
                  class="item-attachment"
                >
                  <v-btn
                    text
                    class="pa-4"
                    small
                  >
                    <v-icon>mdi-file-document-outline</v-icon>
                    <span>{{ item.name }}</span>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    color="red"
                    @click="deleteAttachment(index)"
                  >
                    <v-icon small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- Execution Attachments IF Exists -->
              <div class="step-item-attachments d-flex flex-column align-start">
                <div
                  v-for="(item,index) in selectedResultAttachments"
                  :key="index"
                  class="item-attachment"
                >
                  <v-btn
                    text
                    :href="item.url"
                    target="_blank"
                    class="pa-4"
                    small
                  >
                    <v-icon>mdi-file-document-outline</v-icon>
                    <span>{{ item.name }}</span>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    color="red"
                    @click="deleteResultAttachment(item.uid)"
                  >
                    <v-icon small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="test-result-actions d-flex justify-between">
              <v-btn
                depressed
                class="f-color-white btn-theme text-capitalize rounded-lg"
                color="gray-100"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                height="40px"
                @click="closeTestDialog"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                depressed
                class="f-color-white btn-theme text-capitalize rounded-lg"
                color="primary"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                height="40px"
                @click="selectedResultUid ? updateResult() : addResult() "
              >
                {{ selectedResultUid ? $t('editResult') : $t('addResult') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div
      class="w-100"
    >
      <div
        v-if="showActions"
        class="d-flex justify-space-between align-center mb-5"
      >
        <div class="d-flex align-center">
          <v-btn
            class="text-capitalize bg-white f-color-blue px-0 toggle-btn"
            depressed
            @click="testDialog = true"
          >
            <div class="d-flex align-center">
              <PlusBlueIcon />
              <span class="ml-2 fw-semibold">{{ $t('addResult') }}</span>
            </div>
          </v-btn>
          <v-btn
            v-if="false"
            class="text-capitalize ml-3 px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1"
            depressed
            @click="handleEdit()"
          >
            <div class="d-flex align-center">
              <RerunIcon />
              <span class="ml-2 fw-semibold">{{ $t('rerun') }}</span>
            </div>
          </v-btn>
        </div>
        <div class="d-flex align-center">
          <div class="d-flex align-center mr-5">
            <v-btn 
              icon
              class="text-capitalize px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1"
              depressed 
              @click="$parent.$emit('moveItem', 'previous')"
            >
              <span class="cursor-pointer d-flex align-center">
                <ArrowLeftIcon />
              </span>
            </v-btn>

            <v-btn 
              depressed
              icon
              class="text-capitalize px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1" 
              @click="$emit('moveItem', 'next')"
            >
              <span
                class="mx-2 cursor-pointer d-flex align-center"    
              >
                <ArrowRightIcon />
              </span>
            </v-btn>
          </div>
          <span
            class="close-btn"
            @click="$emit('closeDetail')"
          ><v-icon>mdi-close</v-icon></span>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <h2
        v-if="showName"
      >
        {{ execution.name }}
      </h2>
      <div
        v-if="execution.caseFields.tags"
        class="tags d-flex"
      >
        <v-chip
          v-for="(item, index) in execution.caseFields.tags"
          :key="index" 
          label
          small 
          class="grey lighten-3 blue-grey--text"
        >
          {{ item }}
        </v-chip>
      </div>
    </div>
    <h4>{{ $t('defect.overview') }}</h4>
    <div
      id="execution-detail"
      class="execution-detail my-4"
    >
      <div class="d-flex justify-space-between">
        <div class="block rounded-lg w-50 mr-2 mh-56px bg-gray-theme d-flex align-center">
          <v-select
            id="execution_status"
            v-model="execution.status"
            :menu-props="{'offset-y': true}"
            :items="status"
            label="Status"
            dense
            filled
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            flat
            item-color="grey"
            :hide-details="true"
            @change="$emit('updateExecution', {property: 'status', value: execution.status})"
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              <span       
                class="fw-semibold fs-14px"        
                :class="{
                  'light-blue--text': data.item.toLowerCase().includes('new'),
                  'amber--text text--darken-4': data.item.toLowerCase().includes('incomplete'),
                  'green--text': data.item.toLowerCase().includes('passed'),
                  'red--text': data.item.toLowerCase().includes('failed'),
                }"
              >
                {{ data.item }}
              </span>
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              <span 
                class="fw-semibold fs-14px"
                :class="{
                  'light-blue--text': data.item.toLowerCase().includes('new'),
                  'amber--text text--darken-4': data.item.toLowerCase().includes('incomplete'),
                  'green--text': data.item.toLowerCase().includes('passed'),
                  'red--text': data.item.toLowerCase().includes('failed'),
                }"
              >
                {{ data.item }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="block rounded-lg w-50 ml-2 mh-56px bg-gray-theme d-flex align-center">
          <v-select
            v-model="execution.priority"
            filled
            :menu-props="{'offset-y': true}"
            label="Priority"
            :items="priorities"
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            dense
            :hide-details="true"
            @change="$emit('updateExecution', {property: 'priority', value: execution.priority})"
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              <span     
                class="fw-semibold fs-14px"          
                :class="{
                  'amber--text text--darken-4': data.item.toLowerCase().includes('medium'),
                  'green--text': data.item.toLowerCase().includes('low'),
                  'red--text': data.item.toLowerCase().includes('high'),
                }"
              >
                {{ data.item }}
              </span>
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              <span 
                class="fw-semibold fs-14px"
                :class="{
                  'amber--text text--darken-4': data.item.toLowerCase().includes('medium'),
                  'green--text': data.item.toLowerCase().includes('low'),
                  'red--text': data.item.toLowerCase().includes('high'),
                }"
              >
                {{ data.item }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
      
      <v-expansion-panels
        v-model="overviewCollapsed"
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 no-expansion-header-icon">
            <h4
              class="f-color-blue toggle-btn"
              @click="overviewCollapsed = overviewCollapsed"
            >
              {{ overviewCollapsed == 0 ? $t('defect.showLess') : $t('defect.showMore') }}
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex justify-space-between mb-2">
              <!-- <v-col cols="6">
                <div class="grey lighten-3 pa-2">
                  <h5 class="align-left">{{ $t('template') }}</h5>
                  <div class="align-left contents" v-if="execution.case_fields">
                    {{ execution.case_fields.template }}
                  </div>
                </div>
              </v-col> -->
              <div class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px bg-gray-theme">
                <div class="">
                  <h5 class="align-left">
                    {{ $t('id') }}
                  </h5>
                  <div class="align-left contents fw-semibold fs-14px">
                    {{ execution.externalId }}
                  </div>
                </div>
              </div>
              <div class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px bg-gray-theme">
                <div class="">
                  <h5 class="align-left">
                    {{ $t('lastUpdate') }}
                  </h5>
                  <div class="align-left contents fw-semibold fs-14px">
                    {{ execution.updatedAt }}
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-space-between">
              <div class="block rounded-lg px-3 py-2 w-50 mh-56px bg-gray-theme">
                <div>
                  <h5 class="align-left">
                    {{ $t('defect.assignedTo') }}
                  </h5>
                  <div
                    class="align-left contents fw-semibold fs-14px"
                  >
                    {{ execution.assignedTo ? execution.assignedTo.email : $t('noAssigned') }}
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-expansion-panels
      v-model="descriptionPanel"
      flat
      class="panel-expansion"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="mx-0 px-0 panel-title">
          {{ $t('description') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- description contents -->
          <v-timeline
            dense
            class="timeline-theme"
          >
            <v-timeline-item
              v-for="step in execution.steps"
              :key="step.key"
              right
            >
              <step-item :step-item="step" />
            </v-timeline-item>
          </v-timeline>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      v-model="resultHistoryPanel"
      flat
      class="panel-expansion result-expansion"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="mx-0 px-0 panel-title">
          {{ $t('resultHistory') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="filterResults && filterResults.length">
          <!-- description contents -->
          <v-timeline
            dense
            class="timeline-history"
          >
            <div 
              v-for="(result, index) in filterResults"
              :key="index"
              class="result-content"
            >
              <v-timeline-item
                right
                medium
                class="timeline-item"
                color="grey lighten-2"
              >
                <template v-slot:icon>
                  <span class="grey--text text--darken-1 caption font-weight-medium">{{ index + 1 }}</span>
                </template>
          
                <div
                  v-if="result.reporter"
                  class="expansion-header d-flex justify-space-between"
                >
                  <h4 class="result-name">
                    {{ result.reporter.firstName }} {{ result.reporter.lastName }}
                  </h4>
                  <h4 class="result-date">
                    {{ result.resultCreatedAt }}
                  </h4>
                </div>
                <div class="step-item">
                  <div class="step-item-status">
                    <h5
                      :class="{
                        'light-blue--text': result.status.toLowerCase().includes('new'),
                        'amber--text text--darken-4': result.status.toLowerCase().includes('incomplete'),
                        'green--text': result.status.toLowerCase().includes('passed'),
                        'red--text': result.status.toLowerCase().includes('failed'),
                      }"
                    >
                      {{ result.status.toUpperCase() }}
                    </h5>
                  </div>
                  <div class="step-item-comment">
                    <div v-html="result.comment" />
                  </div>
                  <div class="step-item-attachments d-flex flex-column align-start">    
                    <div
                      v-if="result.images.length"
                      class="images-attachment"
                    >
                      <p>Images</p>
                      <div class="item-attachment">
                        <a
                          v-for="(item,index) in result.images"
                          :key="index"
                          :href="item.url"
                          target="_blank"
                        >
                          <v-img
                            :src="item.url"
                            crossorigin="use-credentials"
                            :alt="item.name"
                            referrerpolicy="origin"
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="result.files.length"
                      class="files-attachment mt-4 d-flex align-start flex-column"
                    >
                      <p>Files</p>
                      <div
                        v-for="(item,index) in result.files"
                        :key="index"
                        class="item-attachment"
                      >
                        <v-btn
                          text
                          :href="item.url"
                          target="_blank"
                          class="py-1"
                          small
                        >
                          <v-icon class="mr-1">
                            mdi-file-document-outline
                          </v-icon>
                          <span>{{ item.name }}</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="step-item-action">
                    <v-btn
                      class="text-capitalize px-1 btn-custom  f-color-blue px-2 toggle-btn mx-1"
                      depressed
                      @click="resultDialog(result.resultUid, result.status, result.comment)"
                    >
                      <v-icon size="16">
                        mdi-pencil
                      </v-icon>
                      {{ $t('edit') }}
                    </v-btn>
                    <v-btn
                      class="text-capitalize px-1 btn-custom f-color-red px-2 toggle-btn mx-1"
                      depressed
                      @click="showResultDialog(result.resultUid)"
                    >
                      <v-icon size="16">
                        mdi-delete
                      </v-icon>
                      {{ $t('delete') }}
                    </v-btn>
                  </div> -->
                </div>
              </v-timeline-item>
            </div>
          </v-timeline>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
          {{ $t('noResultFound') }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { priorityColors } from '@/constants/colors.js';
import { resultStatus } from '@/constants/results.js'
import { formatDate } from '@/utils/util';
import { VueEditor } from "vue2-editor";
import makeAttachment from '@/services/api/attachment';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import PlusBlueIcon from '@/assets/svg/plus-blue.svg';
import RerunIcon from '@/assets/svg/rerun.svg';
import ArrowRightIcon from '@/assets/svg/arrow-right.svg';
import ArrowLeftIcon from '@/assets/svg/arrow-left.svg';
import DeleteResultDialog from './DeleteResultDialog.vue'
import StepItem from '@/views/Tests/Case/Components/StepItem.vue';

export default {
  name: 'DetailView',
  components: {
    VueEditor ,
    PlusBlueIcon,
    RerunIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    DeleteResultDialog,
    StepItem
  },
  props: {
    execution: Object,
    testResults: Array,
    showActions: {
      type: Boolean,
      default: true,
    },
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ['link']
      ],
      files: [],
      resultStatus,
      resultState: null,
      resultComment: null,
      overviewCollapsed: false,
      descriptionPanel: null,
      resultHistoryPanel: [],
      runsPanel: null,
      runs: [],
      status: ['New', 'Incomplete', 'Passed', 'Failed'],
      priorities: ['Low','Medium','High'],
      collapseStep: [],
      collapseResult: [],
      testDialog: false,
      selectedResultUid: null,
      selectedResultAttachments: [],
      showDeleteConfirmDialog: false,
      handleDeletedResultUid: null
    }
  },
  computed: {
    lastUpdated() {
      return formatDate(this.caseItem.updated_at, 'MM/dd/yy');
    },
    filterResults(){

      let imgTypes = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

      return this.testResults.map(item => {
        let images = [];
        let files = [];
        const createdFormat = new Date(item.resultCreatedAt);
        const created_at = `${createdFormat.getFullYear()}/${String(createdFormat.getMonth()+1).padStart(2,'0')}/${new String(createdFormat.getDate()).padStart(2,'0')} ${createdFormat.getHours()}:${createdFormat.getMinutes()}`
        if(item.attachments?.length)
          item.attachments.forEach(element => {
            const { url, name ,fileType ,uid} = element

            if(imgTypes.includes(fileType))
              images.push({url, name, uid})
            else
              files.push({url, name, uid})
          })
        
        return {
          ...item,
          resultCreatedAt:created_at,
          images,
          files
        }
      }
     )
    },
  },
  methods: {
  inited (viewer) {
      this.$viewer = viewer
    },
    show () {
      this.$viewer.show()
    },
    handleEdit() {
      this.$router.push({
        name: 'EditTestCases',
        params: { uid: this.caseItem.test_case_ref },
      });
    },
    deleteAttachment(index){
      this.files.splice(index,1);
    },
    showResultDialog(uid){
      this.showDeleteConfirmDialog = true;
      this.handleDeletedResultUid = uid;
    },
    handleDeleteResponse(value){
      if(value && this.handleDeletedResultUid)
        this.deleteResult(this.handleDeletedResultUid)

      this.showDeleteConfirmDialog = false;
      this.handleDeletedResultUid = null;
    },
    async deleteResultAttachment(uid){
      const attachmentService = makeAttachment(this.$api);
      const handle = this.$route.params.handle
      await attachmentService.deleteAttachment(handle,uid).then(() => {
        this.selectedResultAttachments = this.selectedResultAttachments.filter(element => element.uid != uid)
        const testResultIndex = this.testResults.findIndex(element => element.uid = this.selectedResultUid);
        this.testResults[testResultIndex].attachments = this.selectedResultAttachments
        showSuccessToast(this.$swal, this.$t('success.deleteAttachment'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteAttachment'))
      })

    },
    addResult(){
      const payload = {
        files: this.files,
        status: this.resultState.toLowerCase(),
        comment: this.resultComment
      }

      this.$emit('addResult', payload)
      this.closeTestDialog();
    },
    updateResult(){
      const payload = {
        files: this.files,
        status: this.resultState.toLowerCase(),
        comment: this.resultComment
      }
      this.$emit('updateResult', this.selectedResultUid, payload)
      this.closeTestDialog();
      
    },
    resultDialog(resultUid, status, comment){
      this.resultComment = comment;
      this.resultState = status[0].toUpperCase() + status.slice(1)
      this.selectedResultUid = resultUid;
      this.testDialog = true;
      const selectedResult = this.testResults.filter((element) => {
        if(element.resultUid == resultUid)
          return element.attachments
      });

      if(selectedResult.length)
        this.selectedResultAttachments = selectedResult[0].attachments
      
    },
    deleteResult(resultUid){
      this.$emit('deleteResult', resultUid)
    },
    getPriorityColor(priority) {
      const foundPriority = priorityColors.find((color) => color.testCase === priority);
      return foundPriority?.value || 'unset';
    },
    closeTestDialog(){
      this.files = [];
      this.selectedResultAttachments = [];
      this.resultComment = null;
      this.resultState = null;
      this.selectedResultUid = null;
      this.testDialog = false;
    }
  },
}
</script>
<style scoped>
.result-form-label{
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 500 !important;
  margin-bottom: 6px !important;
  display: block !important;
}
.tags{
  gap: 8px;
}
#input-file-browser{
  display: block;
  height: 120px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #D0D5DD;
  background-color: #F9FAFB;
  color: #0C2FF3;
  font-weight: bold;
  cursor: pointer;
}
#input-file-browser:hover{
  background-color: #eeeeee;
  transition: all 0.5s;
}

</style>
<style>
/* Customize Editor */
.quillWrapper{
  background-color: #f0f0f0;
  border-radius: 16px;
}
.quillWrapper .ql-toolbar{
  border: 0px !important;
}
.quillWrapper #quill-container{
  border: 0px !important;
  height: 120px;
}
.quillWrapper .ql-toolbar .ql-formats svg{
  width: 16px !important;
}
.quillWrapper .ql-toolbar .ql-formats button{
  width: 20px;
}
.ql-editor{
  min-height: auto !important;
}
/* Result buttons */
.test-result-actions{
  gap: 16px;
}
.test-result-actions button{
  width: calc(50% - 16px) !important;
  border-radius: 6px !important;
}
.images-preview{
  margin: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.image-preview img{
  width: 60px;
  object-fit: cover;
  height: 60px;
  border: 1px dashed blue;
}
.image-preview button{
  position: absolute;
}
.execution-detail .v-text-field--filled.v-input--dense .v-label--active, .v-text-field--full-width.v-input--dense .v-label--active{
  color: #667085 !important
}
.v-text-field.v-input--is-focused>.v-input__control>.v-input__slot:after{
  display: none;
}
h2, h3, h4{
  text-align: left;
}
h5{
  color:#667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.collapse-btn{
  color:#0C2FF3;
}
p{
  text-align: left;
}
.flex{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.justify-start{
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.bg-white{
  background-color: white !important;
}
.close-btn:hover{
  cursor: pointer;
}
.f-color-blue{
  color: #0C2FF3 !important;
}
.w-50{
  width: 50%;
}
.align-left{
  text-align: left;
}
.align-left .contents{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0C111D;
}
.toggle-btn{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

.panel-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
/* Customize Expansion */
/* .panel-expansion{
  overflow: hidden;
} */
.v-timeline-item__divider{
  min-width: auto !important;
  margin: 0px -8px;
}
.v-timeline--dense:not(.v-timeline--reverse):before, .v-application--is-rtl .v-timeline--reverse.v-timeline--dense:before{
  left: 11px !important;
}
.v-timeline--dense .v-timeline-item{
  justify-content: flex-end;
  gap: 8px;
}
.timeline-item h4{
  color: #0C111D;
  font-size: 14px;
}
.v-timeline--dense .v-timeline-item__body{
  max-width: max-content;
  min-width: calc(100% - 24px) !important;
  background-color: #F9FAFB;
  border-radius: 8px;
  padding: 16px;
}
.v-timeline--dense .v-timeline-item__body button{
  padding: 0px !important;
}
.step-item{
  gap: 8px
}
.step-item .step-item-status h5{
  font-size: 12px;
  margin-top: 10px;
}
.step-item > div{
  width: calc(50% - 4px);
  flex-shrink: 0;
}
.step-item > div h5{
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.step-item > div p{
  font-size: 14px;
  color: #0C111D;
}
.result-expansion .step-item > div{
  width: 100%;
}
.btn-custom:hover::before{
  display: none;
}
/* Test Results */
.result-expansion .v-timeline-item__dot{
  box-shadow: none !important;
}
.result-expansion .expansion-header h4.result-name{
  font-weight: 500 !important;
  color: #0C111D;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
}
.result-expansion .expansion-header h4.result-date{
  color: #667085;
  font-weight: 400;
  font-size: 13px;
}
.result-expansion .step-item-attachments .viewer img{
  width: 150px;
  height: 100px;
  margin: 8px;
  border: 3px solid #888;
  cursor: pointer;
}
.result-expansion .step-item-attachments .images-attachment .item-attachment{
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.result-expansion .step-item-attachments .images-attachment .item-attachment a{
  border: 1px solid #D0D5DD;
  overflow: hidden;
  width: 120px;
  height: 80px;
  border-radius: 4px;
}
.result-expansion .step-item-attachments .images-attachment .item-attachment a img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item-attachment span{
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;

}
.result-expansion .step-item-action{
  gap: 8px;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

</style>
