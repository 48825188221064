<template>
  <v-layout class="h-screen bg-theme-base">
    <v-row class="ma-4">
      <v-col
        class="pa-0 pr-md-1"
        cols="12"
        md="6"
      >
        <v-card
          class="h-full d-flex flex-column rounded-lg"
          :flat="true"
        >
          <v-row
            class="logo-container"
            justify="start"
          >
            <img
              class="logo"
              src="https://svgshare.com/i/1AmF.svg"
            >
          </v-row>
          <v-row class="h-full">
            <router-view />
          </v-row>
        </v-card>
      </v-col>
      <v-col
        class="d-none d-md-block pa-0 pl-md-1"
        md="6"
      >
        <v-card
          class="auth-wrapper h-full d-flex justify-center align-center rounded-lg"
          :flat="true"
        >
          <div class="auth-banner">
            <img
              class="w-full banner"
              src="https://svgshare.com/i/1Amx.svg"
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-layout>
</template>

<script>
  export default {
    computed: {
      versionString() {
        if (
          process.env.VUE_APP_VERSION &&
          process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
        ) {
          return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
        }
        return '';
      },
    },
  };
</script>

<style scoped>
  .logo-container {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
  .logo {
    width: 50px;
    height: 41px;
  }
  .auth-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://svgshare.com/i/1AnK.svg");
    background-size: contain;
    background-position: top;
    z-index: 0;
    max-width: 160px;
  }
  .auth-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://svgshare.com/i/1Aji.svg");
    background-size: contain;
    background-position: bottom;
    z-index: 1;
    max-width: 160px;
  }
  .auth-banner {
    width: 100%;
    max-width: 497px;
  }
  .auth-banner .banner {
    z-index: 1;
    position: relative;
  }
  .h-screen {
    height: 100vh;
  }
  .h-full {
    height: 100%;
  }
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
