<template>
  <v-data-table
    v-model="selectedItems"
    hide-default-footer
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="data-table-style table-fixed mt-6"
    show-select
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    @click:row="handleClick"
    @input="handleSeleted"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>

    <template v-slot:[`headers.name`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.steps_number`]="{ item }">
      <span class="custom-attribute">{{ item.steps.length }} &nbsp; steps</span>
    </template>
    <template v-slot:[`item.referenced_by`]="{ item }">
      <span class="custom-attribute"> {{ item.referenced_by }} test cases</span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-end">
        <v-menu
          left
          offset-y
          class="font-inter"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left font-inter"
          >
            <v-list-item
              v-if="item.archivedAt == null"
              class="pointer d-flex align-center"
            >
              <EditIcon />
              <v-list-item-content
                class="ml-2"
                @click="$emit('edit', item)"
              >
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="pointer"
              @click="$emit(item.archivedAt == null ? 'archive' : 'unarchive', item)"
            >
              <ArchiveIcon v-if="item.archivedAt == null" />
              <UnArchiveIcon v-else />
              <v-list-item-content class="ml-2">
                {{
                  item.archivedAt == null ? $t('archive') : $t('unarchive')
                }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="pointer"
              @click="$emit('delete', item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import EditIcon from '@/assets/svg/edit.svg';
import ArchiveIcon from '@/assets/svg/archived.svg';
import UnArchiveIcon from '@/assets/svg/unarchive.svg';
import DeleteIcon from '@/assets/svg/delete.svg';

const { mapState } = createNamespacedHelpers('user');

export default {

  components: {
    EditIcon,
    ArchiveIcon,
    DeleteIcon,
    UnArchiveIcon,
  },
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  data() {
    return {
      tags: this.items,
      selectedItems: [],
    };
  },

  methods: {
    handleClick(item, index) {
      this.$emit('row-click', item, index?.index);
    },
    handleSeleted() {
      this.$emit('input', this.selectedItems);
    },
  },

  computed: {
    ...mapState(['currentAccount']),

    isAbleToManage() {
      return this.currentAccount.roleName === 'owner' || this.currentAccount.roleName === 'admin';
    },
  },
};
</script>
