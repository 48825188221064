<template>
  <v-data-table
    hide-default-footer
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="filteredHeaders"
    :items="itemsPerView"
    :item-key="itemKey"
  >
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <div class="mb-2">
          <v-checkbox
            v-model="item.toBeSelected"
            :value="item.toBeSelected"
            color="blue"
            hide-details
            dense
            @change="toBeSelectedHandler"
          />
        </div>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.name }}
          </div>
        </div>
      </td>
    </template>
    <template />
    <template v-slot:[`item.testcases`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-3">
            {{ item.testcases }} test cases
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.testruns`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.runs }} {{ $t('milestone.addTestPlan.testRuns') }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-btn icon>
          <v-icon
            color="gray-500"
            @click="$emit('updateRow', item)"
          >
            {{ tableFilter === 'all' ?
              'mdi-plus-box-outline' : 'mdi-minus-box-outline' }}
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class="getColor(item.priority)">{{ item.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span :class="getColor(item.status)">{{ item.status }}</span>
    </template>
    <template v-slot:[`item.configurations`]="{item}">
      <span :class="getColor(item.customFields?.configurations)">{{ item.customFields?.configurations }}</span>
    </template>
    <template v-slot:[`item.testcases`]="{item}">
      <span class="">{{ item.customFields.caseCount }} test cases</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ formatDueDate(item.createdAt) }}</span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <span class="">{{ item.customFields?.tags }}</span>
    </template>
  </v-data-table>
</template>
  
<script>
import dayjs from 'dayjs';

export default {
    props: {
        tableFilter: {
            type: String,
            default: 'all',
        },
        plansData: {
            type: Array,
        },
        filteredHeaders: {
            type: Array,
        },
        filteredItems: {
            type:Array,
        },
        rowClass: {
            type:Function,
        }
    },
    data() {
        return {
            plans: this.plansData,
            itemKey: 'name',
            isAllPlanChecked: false,
            isSelectedPlanChecked: false,
        };
    },
    computed: {
        itemsPerView() {
            if (this.tableFilter === 'all') {
                return this.filteredItems.filter((item) => !item.selected);
            } else {
                return this.filteredItems.filter((item) => item.selected);
            }
        },
    },
    watch: {
        tableFilter(newValue) {
            this.togglePlans();
        },
    },
    methods: {
      formatDueDate(dueDate) {
        return dayjs(dueDate).format('YYYY-MM-DD')
      },
      getColor(priority) {
        switch(priority){
          case "High":
          case "Failed":
            return "font-weight-bold red--text text--lighten-1"
          case "Medium":
          case "Rerun":
            return "font-weight-bold orange--text text--lighten-1"
          case "Low":
          case "Active":
            return "font-weight-bold green--text text--lighten-1"
          case "Passed":
            return "font-weight-bold deep-purple--text text--lighten-1"
          case "In progress":
            return "font-weight-bold grey--text text--lighten-1"
        }
      },
        togglePlans() {
            const isSelected = this.tableFilter === 'all' ? this.isAllPlanChecked : this.isSelectedPlanChecked;
            this.plans.forEach((plan) => {
                const condition = this.tableFilter === 'all' ? !plan.selected : plan.selected;
                if (condition) {
                    this.$set(plan, 'toBeSelected', isSelected);
                }
            });
        },
        toBeSelectedHandler() {
            const filteredPlans =
                this.tableFilter === 'all'
                    ? this.plans.filter((plan) => !plan.selected)
                    : this.plans.filter((plan) => plan.selected);
            const allTrue = filteredPlans.every((plan) => plan.toBeSelected);

            if (this.tableFilter === 'all') {
                this.isAllPlanChecked = allTrue;
            } else {
                this.isSelectedPlanChecked = allTrue;
            }
        },
    },
};
</script>
  