<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="table-fixed custom-table mt-6"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    :value="selectedItems"
    height="100%"
    show-select
    @input="onRowClick"
    @click:row="handleRowClick"
  >
    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>
    <template v-slot:[`item.name`]="{item}">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class="getColor(item.priority)">{{ item.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span :class="getColor(item.status)">{{ item.status }}</span>
    </template>
    <template v-slot:[`item.configurations`]="{item}">
      <span :class="getColor(item.customFields?.configurations)">{{ item.customFields?.configurations }}</span>
    </template>
    <template v-slot:[`item.testcases`]="{item}">
      <span class="">{{ item.customFields.caseCount }} test cases</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ item.createdAt }}</span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <span class="">{{ item.customFields?.tags }}</span>
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-progress-linear
          rounded
          color="#0c2ff3"
          background-color="#F2F4F7"
          :height="8"
          :value="item?.customFields?.progress"
        />
        <span class="font-weight-regular ml-3">{{ item?.customFields?.progress }}%</span>
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left"
          >
            <v-list-item
              v-if="item.archivedAt === null"
              class="pointer"
              @click="onAddToMilestone(item)"
            >
              <FlagIcon />
              <v-list-item-content class="ml-2">
                {{ $t('plans.addToMilestone') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt === null"
              class="pointer"
              @click="onEdit(item)"
            >
              <EditIcon />
              <v-list-item-content class="ml-2">
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt === null"
              class="pointer"
              @click="onArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('archive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="item.archivedAt !== null"
              class="pointer"
              @click="onUnArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('unarchive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="pointer"
              @click="onDelete(item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div
            v-for="(pic, imgIndex) in item.images"
            :key="imgIndex"
          >
            <v-avatar
              class="ml-n2 custom_border"
              size="30"
            >
              <img
                :src="pic"
                alt=""
              >
            </v-avatar>
          </div>
          <v-avatar
            v-if="item.showCount"
            class="font-weight-bold gray-ish--text ml-n2"
            color="#ebecf0"
            size="30"
          >
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div>
          <v-menu
            content-class="custom_ele elevation-0"
            nudge-bottom="35"
            left
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon color="gray-ish">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                class="pointer"
                @click="onAddToMilestone(item)"
              >
                <FlagIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('addToMilestone') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="pointer"
                @click="onEdit(item)"
              >
                <EditIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('edit') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.customFields?.status==='Active'"
                class="pointer"
                @click="onArchive(item)"
              >
                <ArchieveIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('archive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="item.customFields?.status==='Archived'"
                class="pointer"
                @click="onUnArchive(item)"
              >
                <ArchieveIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('unarchive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="pointer"
                @click="onDelete(item)"
              >
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">
                  {{ $t('delete') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>

import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import FlagIcon from '@/assets/svg/flag.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import { formattedDate } from '@/utils/util';

export default {
  components: {
    DeleteIcon,
    EditIcon,
    FlagIcon,
    ArchieveIcon
  },
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
    clearSelection: Boolean,
  },
  data() {
    return {
      debounce: false,
      selectedItems: [],
    };
  },
  watch: {
    clearSelection(newVal) {
      if (newVal) {
        this.selectedItems = [];
        this.$emit('select-item', this.selectedItems);
      }
    },
  },
  methods: {
    formattedDate,
    onRowClick(item) {
      this.selectedItems = item;
      this.$emit('select-item', this.selectedItems);
    },
    handleRowClick(item) {
      this.$emit('select-row', item);
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onAddToMilestone(item) {
      this.$emit('add-to-milestone', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
    getColor(priority) {
      switch(priority){
        case "High":
        case "Failed":
          return "font-weight-bold red--text text--lighten-1"
        case "Medium":
        case "Rerun":
          return "font-weight-bold orange--text text--lighten-1"
        case "Low":
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Passed":
          return "font-weight-bold deep-purple--text text--lighten-1"
        case "In progress":
          return "font-weight-bold grey--text text--lighten-1"
      }
    },
  },
};
</script>

<style scoped>
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
    background-color: #F9FAFB;
  }
  .pointer {
    cursor: pointer;
  }
  .custom_border {
    border: 2px solid #ffffff;
  }
  .v-data-table table {
    border-collapse: collapse;
  }
  .v-data-table th {
    border: none !important;
  }
  .v-data-table td {
    border: none !important;
    cursor: pointer;
  }
  .v-data-table tr.project-item:hover {
    border: 1px solid #d1e1ff !important;
  }
  .v-data-table .v-data-table__wrapper tbody tr {
    height: 80px;
  }
  .v-data-table .v-data-table__wrapper tbody tr td {
    height: 80px !important;
    padding-top: 0;
  }
  .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background-color: transparent !important;
  }
</style>
