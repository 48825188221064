import { showConfirmationDialog, showErrorToast, showSuccessToast } from '@/utils/toast';

import i18n from '../../i18n';
import makeTestPlanService from '@/services/api/plan';

const state = {
  items: [],
  testPlan: {
    custom_fields: {}
  },
  selectedRuns: [],
};
const mutations = {
  SET_ITEMS: (state, items) => (state.items = items.filter((item) => !item.deleted_at)),
  SET_ITEM: (state, item) => (state.testPlan = item),
  ADD_ITEM: (state, item) => state.items.unshift(item),
  DELETE_ITEM: (state, itemUid) => (state.items = state.items.filter((item) => item.uid !== itemUid)),
  UPDATE_ITEM: (state, updatedItem) => {
    const index = state.items.findIndex((item) => item.uid === updatedItem.uid);
    if (index !== -1) {
      state.items.splice(index, 1, updatedItem);
    }
  },
  UPDATE_SELECTED_RUNS: (state, items) => {
    state.selectedRuns = items;
  },
};

const makeActions = (api) => {
  const planService = makeTestPlanService(api);
  return {
    fetchItems: async function ({ commit, rootState }, { handle, projectKey, perPage, currentPage }) {
      try {
        const dt = await planService.getAllTestPlans(handle, projectKey, perPage, currentPage);
        commit('SET_ITEMS', dt.data.plans);
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    },
    findTestPlan: async function ({ commit, rootState }, { handle, planId, key }) {
      try {
        commit('SET_ITEM', { custom_fields: {} });
        const dt = await planService.findTestPlan(handle, key, planId);
        commit('SET_ITEM', dt.data);
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    },
    getAllTestPlans: async function ({ commit, rootState }, { handle, perPage, currentPage, key }) {
      try {
        const dt = await planService.getAllTestPlans(handle, key, perPage, currentPage);
        commit('SET_ITEMS', dt.data.plans);
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
    searchTestPlans: async function ({ commit, rootState }, { handle, projectKey, text, perPage, currentPage }) {
      try {
        const dt = await planService.searchTestPlans(handle, projectKey, text, perPage, currentPage);
        commit('SET_ITEMS', dt.data.plans);
      } catch (error) {
        console.error('Error searching items: ', error);
      }
    },
    deleteTestPlan: async function ({ dispatch, rootState }, { swal, uid, handle, projectKey, perPage, currentPage }) {
      const result = await showConfirmationDialog(
        swal,
        i18n.t('deleteTestPlan'),
        i18n.t('confirmDeleteTestPlan'),
        'warning',
        i18n.t('delete'),
        i18n.t('cancel')
      );
      if (result.isConfirmed) {
        try {
          const dt = await planService.deleteTestPlan(handle, projectKey, uid);
          if (dt) {
            dispatch('fetchItems', { handle, perPage, currentPage });
            showSuccessToast(swal, i18n.t('success.planDeleted'));
          }
        } catch (error) {
          showErrorToast(swal, i18n.t('error.planDeleteFailed'));
        }
      }
    },
    deleteTestPlans: async function ({ dispatch, rootState }, { swal, handle, projectKey, payload }) {
      try {
        const dt = await planService.deleteTestPlans(handle, projectKey, {
          ...payload,
        });
        if (dt) {
          dispatch('fetchItems', { handle });
          showSuccessToast(swal, i18n.t('success.planDeleted'));
        }
      } catch (error) {
        showErrorToast(swal, i18n.t('error.runDeleteFailed'));
      }
    },
    updateTestPlan: async function ({ commit, rootState }, { swal, handle, uid, projectKey, payload }) {
      try {
        const dt = await planService.updateTestPlan(handle, projectKey, uid, {
          ...payload,
        });
        if (dt) {
          commit('UPDATE_ITEM', dt.data);
          showSuccessToast(swal, i18n.t('success.testRunUpdated'));
        }
        return true;
      } catch (error) {
        console.error('Error updating test plan: ', error);
        showErrorToast(swal, i18n.t('error.runUpdateFailed'));
        return false;
      }
    },
    updateTestPlans: async function ({ commit, rootState }, { swal, handle, uid, projectKey, payload }) {
      try {
        const dt = await planService.updateTestPlans(handle, projectKey, {
          ...payload,
        });
        if (dt) {
          commit('UPDATE_ITEM', dt.data);
          showSuccessToast(swal, i18n.t('success.testRunUpdated'));
        }
        return true;
      } catch (error) {
        console.error('Error updating test plan: ', error);
        showErrorToast(swal, i18n.t('error.runUpdateFailed'));
        return false;
      }
    },
  };
};

export default function makeRun(api) {
  return {
    namespaced: true,
    state,
    mutations,
    actions: makeActions(api),
  };
}
