<template>
  <v-card
    class="white pt-4 px-6 pb-0 my-0"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <v-container fluid>
      <div class="custom-header d-flex flex-row justify-space-between align-start">
        <div class="d-flex flex-column justify-start custom-header ">
          <div class="mb-4">
            <div
              class="back-to-projects"
              @click="handleBackClick"
            >
              <v-icon color="blue">
                mdi-chevron-left
              </v-icon>
              <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
                {{ $t('testruns.create_testrun.back_to_testrun') }}
              </p>
            </div>
          </div>
          <div>
            <div class="mb-1">
              <v-responsive>
                <v-text-field
                  v-model="item.name"
                  dense
                  class="d-flex ma-0 font-inter black--text font-weight-bold text-h4"
                  append-outer-icon="mdi-pencil"
                  hide-details
                  @change="updateFilter"
                />
              </v-responsive>
            </div>
            <div>
              <v-responsive>
                <v-text-field
                  v-model="item.customFields.description"
                  :value="item.customFields.description"
                  class="d-flex ma-0 font-inter pt-0 shrink"
                  hide-details
                  @change="updateFilter"
                />
              </v-responsive>
            </div>
          </div>
        </div>
        <div
          cols="auto"
          class="d-flex flex-row align-center pt-0"
          height="40"
        >
          <span class="mr-2">{{ $t('testruns.status') }}</span>
          <v-combobox
            v-model="item.status"
            type="text"
            dense
            single-line
            filled
            placeholder="Active"
            hide-details
            :items="run_statuses"
            class="rounded-lg ma-0"
            clear-icon="body-2"
            width="90"
            @change="updateFilter"
          />
        </div>
      </div>
      <v-row />
      <v-row>
        <v-col>
          <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
            {{ $t('testruns.create_testrun.milestone') }}
          </p>
          <v-autocomplete
            v-model="item.testMilestones"
            type="text"
            dense
            single-line
            filled
            placeholder="Choose milestone"
            hide-details
            :items="milestones"
            item-text="name"
            item-value="uid"
            multiple
            class="rounded-lg"
            clear-icon="body-2"
            @change="updateFilter"
          />
        </v-col>
        <v-col>
          <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
            {{ $t('testruns.create_testrun.priority') }}
          </p>
          <v-combobox
            v-model="item.priority"
            type="text"
            dense
            single-line
            filled
            placeholder="Choose Priority"
            hide-details
            :items="run_priorities"
            class="rounded-lg"
            clear-icon="body-2"
            @change="updateFilter"
          />
        </v-col>
        <v-col>
          <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
            {{ $t('testruns.create_testrun.tags') }}
          </p>
          <v-combobox
            v-model="item.customFields.tags"
            type="text"
            dense
            single-line
            filled
            placeholder="Choose Tag"
            hide-details
            :items="run_tags"
            class="rounded-lg"
            clear-icon="body-2"
            @change="updateFilter"
          />
        </v-col>
      </v-row>
    </v-container>
    <slot name="additional-actions" />
    <RunDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('testruns.edit_testrun.title')"
      :content="$t('testruns.edit_testrun.content')"
      :btn_label="$t('testruns.edit_testrun.btn_label')"
      color="danger"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-card>
</template>

<script>
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
export default {
  name: 'RunEditor',
  components: {
    RunDiscardDialog,
  },
  props: {
    item: Object,
    milestones: Array,
  },
  data() {
    return {
      run_statuses: ["Active", "Incomplete", "Passed", "Failed"],
      run_priorities: ["High", "Medium", "Low"],
      run_tags: ["#smoke", "#regression", "#feature", "#security", "#integration"],
      run_assigns: ["Andrew N", "Oleksandr K", "Kznaeh P", "Monkey K"],
      forms: {
        status: '',
        assign: '',
        milestone: '',
        priority: '',
        tags: ''
      },
      showConfirmBackDialog: false,
    };
  },
  methods: {
    
    updateFilter() {
      this.$emit('update-filter', this.item);
    },

    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'Runs' });
    },
  },
};
</script>

<style scoped>
h2{
  font-weight: 900;
}

.custom-header{
  height: 118px;
}

.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.responsive-text-field {
  display: inline-block;
  width: auto;
}

/* .horizontal-margin {
  margin: 0px 10px;
} */

/* .tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  box-shadow: none;
} */
</style>
