import { MilestoneTableHeader, TestPlansTableHeader,TestRunTableHeader,TestCaseTableHeader } from '@/constants/grid';

const state = {
  milestoneTableHeaders: null,
  planTableHeaders: null,
  runTableHeaders: null,
  caseTableHeaders: null,
};

const getters = {
  milestoneTableHeaders: (state) => state.milestoneTableHeaders,
  planTableHeaders: (state) => state.planTableHeaders,
  runTableHeaders: (state) => state.runTableHeaders,
  caseTableHeaders: (state) => state.caseTableHeaders,
};

const mutations = {
  setHeaders(state, { type, headers }) {
    if (type === 'milestone') {
      state.milestoneTableHeaders = headers;
    } else if (type === 'plan') {
      state.planTableHeaders = headers;
    }
    else if (type === 'run') {
      state.runTableHeaders = headers;
    }
    else if (type === 'case') {
      state.caseTableHeaders = headers;
    }
  }
};

const actions = {
  /**
   * Initialize headers if not already set.
   */
  initializeHeaders({ commit, state }, type) {
    const headerMap = {
      milestone: { headers: MilestoneTableHeader, currentHeaders: state.milestoneTableHeaders },
      plan: { headers:TestPlansTableHeader, currentHeaders: state.planTableHeaders },
      run: { headers: TestRunTableHeader, currentHeaders: state.runTableHeaders },
      case: { headers: TestCaseTableHeader, currentHeaders: state.caseTableHeaders },
    };

    const selectedHeader = headerMap[type];
    
    if (selectedHeader && !selectedHeader.currentHeaders) {
      commit('setHeaders', { type, headers: selectedHeader.headers });

    }
  },

  /**
   * Reset headers to initial values.
   */
  resetHeaders({ commit }, type) {
    const headerMap = {
      milestone: MilestoneTableHeader,
      plan: TestPlansTableHeader,
      run: TestRunTableHeader,
      case: TestCaseTableHeader,
    };
    
    if (headerMap[type]) {
      commit('setHeaders', { type, headers: headerMap[type] });
    }
  },

  /**
   * Update headers based on type and provided headers.
   */
  updateHeaders({ commit }, { type, headers }) {
    commit('setHeaders', { type, headers });
  },
};

export default function makeHeader() {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
}
