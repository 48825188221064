<template>
  <div>
    <v-dialog
      v-model="isOpenStatus"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">
              {{ $t('edit') }}
            </h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('priority') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedPriority"
              placeholder="Choose priority"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              :items="priorities"
              item-text="text"
              item-value="value"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38" 
              :menu-props="{ offsetY: true }"
            />

            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('status') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedStatus"
              placeholder="Choose tag"
              :items="status"
              item-text="text"
              item-value="value"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38" 
              :menu-props="{ offsetY: true }"
            />

            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('assignTo') }}
              </v-label>
            </div>

            <v-select 
              :items="filteredAssignees"   
              :placeholder="$t('chooseUser')"
              :item-text="item => `${item.firstName} ${item.lastName}`"
              :item-value="item => `${item.firstName} ${item.lastName}`"
              append-icon="mdi-chevron-down"
              hide-details
              dense 
              height="38"
              flat
              background-color="#F9F9FB"
              class="rounded-lg field-theme custom-prepend"
              :menu-props="{ offsetY: true }"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field 
                      v-model="searchAssignee" 
                      class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                      :placeholder="$t('search')"
                      height="40"
                      background-color="#F9F9FB"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <SearchIcon />
                      </template>
                    </v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </template>
                
              <template v-slot:no-data>
                <span class="font-weight-regular fs-16px text-theme-label">{{ $t('noMatchingAssignees') }}</span>
              </template>

              <template v-slot:selection="{ item }">
                <span class="font-weight-regular fs-16px text-theme-label">{{ item.firstName }} {{ item.lastName }}</span>
              </template>

              <template v-slot:item="{ item, on, attrs }">
                <v-list-item class="mh-36px cursor-pointer">
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <span
                        v-bind="attrs"
                        class="font-weight-regular fs-16px text-theme-label"
                        @click="on.click"
                      >{{ item.firstName }} {{ item.lastName }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex d-flex justify-space-between">
        <v-btn
          width="168px"
          color="#F2F4F7"
          full-width
          height="40"
          depressed
          class="text-capitalize btn-theme"
          elevation="0"
          @click="$emit('closeDialog')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="168px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="$emit('clickSave', selectedPriority, selectedStatus)"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
export default {
  name: 'EditRunDialog',
  components: {
    SearchIcon
  },
  props: {
    isOpen: Boolean,
    items: Array,
    assignees: Array
  },
  data() {
    return {
      selectedStatus: "",
      selectedPriority: "",
      isOpenStatus: this.isOpen,
      searchAssignee: '',
      priorities: [
        { value: 'High', text: 'High', color: '#EF5350' },
        { value: 'Medium', text: 'Medium', color: '#FFA726' },
        { value: 'Low', text: 'Low', color: '#66BB6A' },
      ],
      status: [
        { value: 'New', text: 'New', color: '#42A5F5' },
        { value: 'Incomplete', text: 'Incomplete', color: '#FFA726' },
        { value: 'Passed', text: 'Passed', color: '#66BB6A' },
        { value: 'Failed', text: 'Failed', color: '#EF5350' },
      ],
    };
  },
  computed: {
    filteredAssignees() {
      return this.assignees.filter(assignee => {
        return assignee.firstName.toLowerCase().includes(this.searchAssignee.toLowerCase()) ||
          assignee.lastName.toLowerCase().includes(this.searchAssignee.toLowerCase());
      });
    }
  },
  watch: {
    isOpen(newVal) {
      this.isOpenStatus = newVal;
    }
  },
  methods: {
    clickOutside() {
      this.$emit("closeDialog");
    },
  }

};
</script>
<style>
.select-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
</style>