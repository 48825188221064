<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ isEditMode ? $t('customFieldPage.editCustomField') : $t('customFieldPage.createCustomField') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-left">
                <v-label class="fs-14px text-theme-label font-weight-medium">
                  {{ $t('name') }} <strong class="red--text text--lighten-1">*</strong>
                </v-label>
              </div>
              <v-text-field
                v-model="customField.name"
                type="text"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
                dense
                height="38px"
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-left">
                <v-label class="fs-14px text-theme-label font-weight-medium">
                  {{ $t('customFieldPage.dataType') }}
                </v-label>
              </div>
              <v-select
                v-model="customField.type"
                :items="dataTypes"
                item-text="name"
                item-value="type"
                class="rounded-lg pt-0 field-theme custom-prepend mh-38px"
                background-color="#F9F9FB"
                :placeholder="$t('customFieldPage.chooseDataType')"
                :rules="requiredRules"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true }"
              />
            </v-col>

            <template v-if="isAbleToAddItem">
              <OptionItems
                :type="customField.type"
                :items="customField.options"
                @remove-item="removeOptionItem"
              />

              <v-col
                v-if="isAbleToAddItem"
                cols="12"
                class="text-right pt-0"
              >
                <v-btn
                  color="primary"
                  class="white--text text-capitalize btn-theme"
                  depressed
                  height="38px"
                  elevation="0"
                  @click="onAddItem()"
                >
                  {{ $t('add') }}
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="168px"
        color="#F2F4F7"
        full-width
        height="40"
        :depressed="true"
        class="text-capitalize btn-theme"
        elevation="0"
        @click="onCancel()"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        width="168px"
        class="btn-theme white--text text-capitalize"
        height="40"
        color="primary"
        :depressed="true"
        full-width
        elevation="0"
        :disabled="!validForm"
        @click="onCreate()"
      >
        {{ isEditMode ? $t('save') : $t('create') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { dataTypes } from '@/constants/customField.js';

import OptionItems from './OptionItems.vue';

export default {
  name: 'CreateUpdateCustomField',

  components: {
    OptionItems,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      dataTypes: dataTypes,
      customField: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    isEditMode() {
      return !!this.customField.uid
    },

    isAbleToAddItem() {
      if (!this.customField.type) {
        return false
      }

      const dataType = dataTypes.find(item => item.type === this.customField.type)

      return dataType?.isOptionsRequired
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.customField = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        type: this.data.type || '',
        source: this.data.source || '',
        options: this.data.options || [],
      }
    },

    'customField.type': {
      handler: function () {
        if (this.isEditMode && this.customField.type === this.data.type) {
          this.customField.options = this.data.options
        } else {
          this.customField.options = []
        }
      }
    }
  },

  methods: {
    onAddItem() {
      this.customField.options.push('')
    },

    removeOptionItem(index) {
      this.customField.options.splice(index, 1)
    },

    onCreate() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update-custom-field' : 'create-custom-field', this.customField)
    },

    onCancel() {
      this.$emit('close-dialog')
    }
  }
}
</script>