import axios from 'axios'

import makeAttachmentService from '../../services/api/attachment';

const state = {
  uploadedFiles: []
}

const mutations = {
  addUploadedFile: (state, uploadedFile) => {
    state.uploadedFiles.push(uploadedFile)
  },
  removeUploadedFile: (state, file) => {
    const { key}  = file;
    const fileIndex = state.uploadedFiles.findIndex(element => element.key == key);
    state.uploadedFiles.splice(fileIndex,1)
  },
  updateFileProgress: (state, {uploadingFile , progress}) => {
    const { key } = uploadingFile;
    const fileIndex = state.uploadedFiles.findIndex(element => element.key == key)
    state.uploadedFiles[fileIndex].progress = progress
  },
  resetUploadedFiles: (state) => {
    state.uploadedFiles = []
  },
  setUploadFailed: (state, file) => {
    const { key}  = file;
    const fileIndex = state.uploadedFiles.findIndex(element => element.key == key);
    state.uploadedFiles[fileIndex].failed = true;
  },
}

const getters = {
  uploadedFiles: (state) => state.uploadedFiles
}

const makeActions = (api) => {
  const attachmentService = makeAttachmentService(api);

  return {
    removeUploadedFile({commit}, file){
      commit('removeUploadedFile', file)
    },
    // TODO Attachment APIS
    async deleteUploadedFile(_, {handle, id, relatedTo}){
      await attachmentService.cleanupAttachments(handle, id, relatedTo)
    },
    async retryUpload({commit,dispatch}, {handle, attachment, mediaType}){

      commit('removeUploadedFile', attachment);

      await dispatch('uploadToServer', { handle, mediaType: attachment.mediaType, relatedTo: attachment.relatedTo ,relatedToUid: attachment.relatedToUid, file: attachment.file})
    },
    async uploadToServer({commit, dispatch, getters},{handle, mediaType ,relatedTo, relatedToUid, file}) {

      const key = getters['uploadedFiles'].length
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const attachment = {
        key,  
        fileName: file.name,
        ...(relatedTo ? { relatedTo } : null),
        ...(relatedToUid? {relatedToUid} : null),
        size: file.size,
        fileType: file.type,
        mediaType,
        sizeInKB: file.size/1000,
        progress: 0,
        file,
        source,
        extension: file.type.substr(file.type.indexOf('/')+1).toUpperCase(),
        failed: false,
      };
      commit('addUploadedFile', attachment);
      
      const { data } = await attachmentService.getSignedAttachmentUrl(handle, attachment).catch(() => commit('setUploadFailed', attachment));
      
      return await dispatch('uploadFile',{attachment, signedUrl: data.upload_url, headers: data.client_headers}).then(() => {
        commit('removeUploadedFile', attachment)
        if(mediaType == 'profile-picture')
          return data.object_url;
        return data.uid
      }).catch(async (error) => {
        await dispatch('deleteUploadedFile', {handle, id: data.uid, relatedTo: attachment.relatedTo})
        
        if(axios.isCancel(error))
          commit('removeUploadedFile', attachment)
        else
          commit('setUploadFailed', attachment);
       
      });
    },
    async uploadFile({commit},{attachment, signedUrl, headers}) {      

      return await axios.put(signedUrl, attachment.file, {
        cancelToken: attachment.source.token,
        headers,
        onUploadProgress: (progressEvent) => {
          const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          commit('updateFileProgress', {uploadingFile: attachment, progress})
        },
      })
      .then(response => response)
      .catch(err => {
          throw err
      }); 
    }

  }
}

export default function makeAttachment(api) {
  return {
    namespaced: true,
    state,
    mutations,
    getters,
    actions: makeActions(api),
  };
}
