<template>
  <v-container
    class="white fill-height align-start card workspace_list"
    fluid
  >
    <v-row>
      <v-col
        cols="4"
        sm="4"
        class="search-bar-style"
      >
        <v-text-field
          v-model="searchTerm"
          class="text-field mr-3 round-8 "
          :placeholder="$t('placeHolder.searchByNameId')"
          dense
          filled
          single-line
        >
          <template v-slot:prepend-inner>
            <iconSearch class="mr-2" />
          </template>
        </v-text-field>
        <TestCasesFilter @filters="applyFilters" />
      </v-col>
      <v-col
        cols="8"
        class="search-bar-style setting-btn-style"
      >
        <div class="btn-selector">
          <template>
            <div class="text-center">
              <SettingsMenu :filter-items="filteredMenuHeaders" />
            </div>
          </template>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
      >
        <v-data-table
          v-model="selectedRows"
          :headers="filteredHeaders"
          :items="isSelectedData ? selectedData : displayTableData"
          item-key="uid"
          show-select
          class="data-table-style"
          :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
          @input="updateSelectedRows"
          @click:row="handleClick"
        >
          <template v-slot:[`header.actions`]="{ header }">
            <div class="d-none">
              {{ header.text }}
            </div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <span class="custom-attribute">{{ item.externalId }}</span>
          </template>
          <template v-slot:[`item.assignDate`]="{ item }">
            <span class="custom-attribute">{{ item.lastAssignedAt }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span 
              :class="{
                'light-blue--text': item.status.toLowerCase().includes('new'),
                'amber--text text--darken-4': item.status.toLowerCase().includes('incomplete'),
                'green--text': item.status.toLowerCase().includes('passed'),
                'red--text': item.status.toLowerCase().includes('failed'),
              }"
            >{{ item.status }}</span>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <span
              v-if="item.priority"
              :class="{
                'amber--text text--darken-4': item.priority.toLowerCase().includes('medium'),
                'green--text': item.priority.toLowerCase().includes('low'),
                'red--text': item.priority.toLowerCase().includes('high'),
              }"
            >{{ item.priority }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="custom-attribute">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.project`]="{ item }">
            <span class="custom-attribute">{{ item.projectName }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row justify-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  class="text-left"
                >
                  <!-- Hide Pinata for now -->
                  <v-list-item v-if="false">
                    <v-list-item-icon>
                      <pinata-icon />
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('runWithPinata') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('selectedExecutions', [item])">
                    <v-list-item-icon>
                      <v-icon color="#344054">
                        mdi-plus
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('addResult') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:[`item.tags`]="{item}">
            <div
              v-if="item.caseFields.tags?.length"
              class="tags"
            >
              <v-chip
                v-for="(item,index) in item.caseFields.tags"
                :key="index"
                x-small
                class="light-blue white--text"
              >
                {{ item }}
              </v-chip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { workspaceTableHeader } from '@/constants/grid';
import TestCasesFilter from '@/views/Tests/Case/Components/Filter.vue';
import iconSearch from '@/assets/svg/search-outline-16x16-gray.svg';
import { createNamespacedHelpers } from 'vuex';
import pinataIcon from '@/assets/svg/pinata.svg'
import dayjs from 'dayjs';
const { mapState } = createNamespacedHelpers('user');

export default {
  components: {
    TestCasesFilter,
    iconSearch,
    SettingsMenu,
    pinataIcon
  },
  props: {
    caseItems: Array,
    allowAction: {
      type: Boolean,
      default: true
    },
    execution: {
      type: Object
    },
    isDetailCollapsed:{
      type: Boolean,
      default: false
    },
  },
  data()
  {
    return {
      isSelectedData: false,
      selectedRows: [],
      headers: workspaceTableHeader,
      searchTerm: '',
      filters: null,
      enableRestoreDefault: false,
      selectedData: [],
    };
  },
  computed: {
    ...mapState(['currentAccount']),
    folderSelected()
    {
      return this.$store.state.folder.selectedFolderUID ? 1 : 0;
    },
    selectedItemsTitles()
    {
      return this.selectedItems.map((i) => i.text).join(', ');
    },
    filteredMenuHeaders()
    {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders()
    {
      return this.headers.filter((header) =>
      {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    

    displayTableData() {
      return this.caseItems.filter((item) => {

        if(
          this.searchTerm.length 
          && item.name.toLowerCase().indexOf(this.searchTerm.toLocaleLowerCase())
          && item.externalId.toLowerCase().indexOf(this.searchTerm.toLocaleLowerCase())
          
          )
          return false

        if(this.filters && (this.filters.priorities.length || this.filters.tags.length)){
            if(this.filters.priorities && this.filters.priorities.length && !this.filters.priorities.includes(item.priority))
              return false

            if(this.filters.tags && this.filters.tags.length){
              let tagExists = false;
              for(const tag of item.caseFields.tags)
                if(this.filters.tags.includes(tag))
                  tagExists = true;

                return tagExists
              }
          }


         if(item.lastAssignedAt)
          item.lastAssignedAt = dayjs(item.lastAssignedAt).format('MM/DD/YY') 
          
          return item
        })
    },
  },
  methods: {

    getColor(priority)
    {
      if (priority == 'High') {
        return 'text-red';
      } else if (priority == 'Medium') {
        return 'text-yellow';
      } else {
        return 'text-green';
      }
    },
    updateSelectedRows(){
      this.$emit('updateRows', this.selectedRows)
    },
    applyFilters(filters){
      this.filters = filters
    },
    handleClick(row)
    {
      this.$emit('expandDetail', row);
    },
    restoreDefault()
    {
      this.enableRestoreDefault = false;
      this.handleColumnReset();
    },
    handleColumnReset()
    {
      this.headers.map((header) =>
      {
        header.isSelected = true;
      });
    },
  },
};
</script>
<style scoped>
.workspace_list .v-data-table .v-data-table__wrapper tbody tr td{
  height: 50px !important;
}
.workspace_list .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):hover{
  background-color: #fafafa !important;
}
.workspace_list .v-data-table .v-data-table__wrapper tbody tr{
  font-weight: 500;
}
.workspace_list{
  font-family: 'Inter', sans-serif !important;
}
.workspace_list .v-data-table .v-data-table__wrapper tbody tr td .tags{
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  max-width: 200px;
}
.round-8 {
  border-radius: 8px;
}

.round-6 {
  border-radius: 6px;
}

.btn-selector {
  position: relative;
}

.modal-main-area {
  height: 100%;
  padding: 32px 32px 32px 32px;
}

.dialog-title {
  font-weight: 900 !important;
}

.filter-dialog {
  padding-top: 15px;
}

.dialog-action {
  width: 90%;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.btn-selector .selector-wrapper {
  position: relative;
}

.selector-style {
  position: absolute;
  right: 0;
  left: unset;
  top: 30px;
  min-width: 240px;
}

.modal-btn {
  width: 45%;
}

.f-color-white {
  color: white !important;
}

.text-red {
  color: #ef5350;
}

.text-green {
  color: #66bb6a;
}

.text-yellow {
  color: #ffa726;
}

.align-start {
  align-items: baseline !important;
  font-family: Inter !important;
}

.search-box-style {
  padding-top: 0;
  border-radius: 8px;
}

.search-bar-style {
  display: flex;
  padding-bottom: 0;
  justify-content: space-between;
}

.setting-btn-style {
  display: flex;
  justify-content: flex-end;
}

.setting-btn {
  position: absolute;
  right: 10px;
  width: 40px !important;
  min-width: 40px !important;
}

.breadcrumb-container {
  padding: 0;
}

.breadcrumb-container ul {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
}

.create-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}

.create-btn button {
  color: white !important;
  margin-top: 10px;
}

.bottom-input-style {
  margin-top: 0;
  border-radius: 5px;
  margin-right: 10px;
}

.bottom-input-style .v-text-field__slot {
  padding-left: 10px;
}

.bottom-input-style .v-select__selections {
  padding-left: 10px;
}

.data-table-style {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.data-table-style tbody tr {
  /* height: 50px !important; */
  line-height: 0% !important;
  cursor: pointer;
}

.data-table-style tbody tr:hover,
.data-table-style tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: #d0d5dd !important;
}

.v-input__prepend-inner {
  padding-left: 10px;
}

.v-list-item__content {
  text-align: start;
}

.search-field .v-input__slot {
  display: flex;
  align-items: center !important;
}

.search-field .v-input__prepend-inner {
  align-self: center;
  margin-top: 0 !important;
  padding-left: 0px;
  padding-right: 8px !important;
}

.text-field .v-input__slot {
  background-color: #f9f9fb !important;
}

.btn-restore {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
}

.menu-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.absolute {
  position: absolute;
}

.bottom-right {
  display: flex;
  justify-content: right;
  right: 24px;
  bottom: 16px;
}

.f-color-blue {
  color: #0c2ff3;
}

.action-btn .v-list-item__title {
  display: flex;
  justify-content: flex-start;
}

.h-100 {
  height: 100%;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  display: block;
}

h2.blank-title {
  text-align: center;
}

p.blank-description {
  max-width: 500px;
  text-align: center;
}

.none {
  display: none;
}

.custom-attribute {
  white-space: nowrap;
}</style>
