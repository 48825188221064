<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="filteredHeaders"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`item.user`]="{ item }">
      <span class="header_text">
        {{ item.name }}
      </span>
    </template>
    <template v-slot:[`header.role`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.lastActivity`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.project`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.tag`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.email`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
      
      
    <template v-slot:[`item.role`]="{ item }">
      <td class="text-start">
        <v-menu
          offset-y
          :position-y="10"
        >
          <template #activator="{ on }">
            <div
              role="button"
              v-on="on"
            >
              {{ item.role }}
              <v-icon class="ml-3">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
        </v-menu>
      </td>
    </template>
    <template v-slot:[`item.tag`]="{ item }">
      <td class="text-start">
        <v-menu
          offset-y
          :position-y="10"
        >
          <template #activator="{ on }">
            <div
              role="button"
              v-on="on"
            >
              {{ item.tag && item.tag.length ? `${item.tag[0]} +${item.tag.length - 1}` : '' }}
              <v-icon class="ml-3">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <v-list class="pt-4">
            <v-list-item
              v-for="tag in item.tag"
              :key="tag"
              class="m-0"
            >
              <div class="d-flex align-start">
                <div class="d-flex align-center mt-2">
                  <span class="mr-2">{{ tag }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </template>
    <template v-slot:[`item.email`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.email }}
      </span>
    </template>
    <template v-slot:[`item.lastActivity`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.lastActivity }}
      </span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <div class="custom-div mr-2" />
        <v-avatar
          class="mr-2"
          size="40"
        >
          <img
            :src="resolveAvatarUrl(item.avatar_url)"
            width="100%"
            alt="logo"
          >
        </v-avatar>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.first_name }} {{ item.last_name }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.project`]="{ item }">
      <td class="d-flex justify-space-between align-center">
        <span class="gray-ish--text text-subtitle-1">
          {{ item.project }}
        </span>
      </td>
    </template>
  </v-data-table>
</template>
  
  <script>
  import { formattedDate } from '@/utils/util';
  
  export default {
    components: {
    },
    props: {
      filteredHeaders: Array,
      itemKey: String,
      items: Array,
    },
    methods: {
      formattedDate,
      resolveAvatarUrl(avatarUrl) {
        return avatarUrl || require('@/assets/png/avatar.png');
      }
    },
  };
  </script>
  